// const getLanguagesFromConfig = (opt: ITranscriptionLanguageOption[]) => {
//   let currL = '';
//   let newL: clientLang[] = [];
//   opt.forEach((o) => {
//     let langAllowed = false;
import { clientLang, IConfig, IConfiguration, ILanguage, IModelParameter, ISttStatus, ISttPipelines, Task, ITttPipelines } from './ISettings';

//     if (o.languageCode !== currL) {
//       opt
//         .find((op) => op.languageCode === o.languageCode)
//         ?.domains.forEach((d, i, arr) => {
//           if (d.allowed === true) {
//             langAllowed = true;
//           }
//           if (i === arr.length - 1 && langAllowed) {
//             langAllowed = false;
//             d.models.forEach((m) => {
//               if (m.available === true && m.allowed) {
//                 langAllowed = true;
//               }
//             });
//           }
//         });

//       if (langAllowed) {
//       }

//       newL = [...newL, { languageCode: o.languageCode, available: langAllowed }];
//       currL = o.languageCode;
//     }
//   });

//   return newL;
// };

export const isSelectedVersionRealTime = (config: IConfig, sttStatus: ISttStatus) => {
  const languages = sttStatus.frameworks.map((framework) => framework.languages).flat();
  const ver = config.stt.model.value;
  const lan = config.stt.language.value;
  const dom = config.stt.domain.value;

  for (const language of languages.filter((l) => l.code === lan)) {
    for (const domain of language.domains.filter((d) => d.code === dom)) {
      const model = domain.models.find((m) => m.code === ver);
      if (!!model) {
        return model.isRealtime;
      }
    }
  }

  return false;
};

export const getLanguagesFromConfig = (options: ILanguage[]) => {
  let newOptions: clientLang[] = [];

  for (let i = 0; i < options.length; i++) {
    const option = options[i];

    // If language is not allowed just continue to the next
    if (!option.isAllowed) {
      newOptions.push({ languageCode: option.code, available: false });
      continue;
    }

    let langAvailable = false;

    // Try to find at least 1 available model version
    for (let j = 0; j < option.domains.length; j++) {
      const domain = option.domains[j];

      // If domain is not allowed you will definitely not find an available model
      if (!domain.isAllowed) continue;

      for (let k = 0; k < domain.models.length; k++) {
        const model = domain.models[k];

        // Found a model that may be used
        if (model.isAvailable && model.isAllowed) {
          langAvailable = true;
          break;
        }
      }

      // If we found a model we can stop searching
      if (langAvailable) break;
    }

    newOptions.push({ languageCode: option.code, available: langAvailable });
  }

  return newOptions;
};

// const generateDomainVersion = (opt: ITranscriptionLanguageOption[], currLang: string, currDomain: string) => {
//     const correctOpts = opt.find((opt) => opt.languageCode === currLang)?.domains;
//     if (!correctOpts) return [];
//     const correctDomain = correctOpts.find((o) => o.domainCode === currDomain);
//     return correctDomain ? correctDomain.models : [];
// };

/*export const generateDomains = (opt: ILanguage[], currLang: string) => {
  const correctDomain = opt.find((opt) => opt.code === currLang);
  return correctDomain ? correctDomain.domains : [];
};*/

export const transformConfiguration = (configuration: IConfiguration) => {
  const configurationDictionary: ISttPipelines = {}
  const automaticPunctuationDictionary: ITttPipelines = {}
  const automaticTruecasingDictionary: ITttPipelines = {}
  const inverseTextNormalizationDictionary: ITttPipelines = {}
  const automaticReplacementDictionary: ITttPipelines = {}

  configuration.forEach(taskConfigration => {
    const {
      task,
      configOptions
    } = taskConfigration

    switch (task) {
      case Task.asr:
        //TODO: Remove or comment out this mock for commit
        //NOTE: Only to test offline model
        /*configOptions.push({
          tag: "NEMO:sl-SI:COL:1234",
          parameters: {},
          features: ["offlineAsr"],
        })*/
        configOptions.forEach(singleConfig => {
          const splitted = singleConfig.tag.split(":")
          const framework = splitted[0]
          const language = splitted[1]
          const domain = splitted[2]
          const version = splitted[3]

          const parameters: IModelParameter[] = []
          if (singleConfig.parameters.enableUnks) {
            parameters.push({
              name: 'enableUnks',
              type: singleConfig.parameters.enableUnks.type,
              values: singleConfig.parameters.enableUnks.values,
            })
          }
          if (singleConfig.parameters.enableInterims) {
            parameters.push({
              name: 'enableInterims',
              type: singleConfig.parameters.enableInterims.type,
              values: singleConfig.parameters.enableInterims.values,
            })
          }
          if (singleConfig.parameters.enableSd) {
            parameters.push({
              name: 'enableSd',
              type: singleConfig.parameters.enableSd.type,
              values: singleConfig.parameters.enableSd.values,
            })
          }

          const frameworkExists = framework in configurationDictionary;
          if (!frameworkExists) {
            configurationDictionary[framework] = {}
          }


          const languageExists = language in configurationDictionary[framework]
          if (!languageExists) {
            configurationDictionary[framework][language] = {}
          }

          const domainExists = domain in configurationDictionary[framework][language]
          if (!domainExists) {
            configurationDictionary[framework][language][domain] = {}
          }

          const versionExists = version in configurationDictionary[framework][language][domain]
          if (!versionExists) {
            configurationDictionary[framework][language][domain][version] = {
              features: singleConfig.features,
              parameters,
            }
            return;
          }

          throw new Error(`There should not be two same versions of asr tag: ${singleConfig.tag}`)
        })
        break;
      case Task.itn:
        configOptions.forEach(singleConfig => {
          const splittedTag = singleConfig.tag.split(":")
          const serviceProvider = splittedTag[0]
          const language = splittedTag[1]
          const domain = splittedTag[2]
          const version = splittedTag[3]

          const parameters: IModelParameter[] = []

          const languageExists = language in inverseTextNormalizationDictionary;
          if (!languageExists) {
            inverseTextNormalizationDictionary[language] = {}
          }

          const domainExists = domain in inverseTextNormalizationDictionary[language]
          if (!domainExists) {
            inverseTextNormalizationDictionary[language][domain] = {}
          }

          const versionExists = version in inverseTextNormalizationDictionary[language][domain]
          if (!versionExists) {
            inverseTextNormalizationDictionary[language][domain][version] = {
              features: singleConfig.features,
              parameters,
              serviceProvider
            }
            return;
          }

          throw new Error(`There should not be two same versions of itn tag: ${singleConfig.tag}`)
        })
        break;
      case Task.pc:
        configOptions.forEach(singleConfig => {
          const splittedTag = singleConfig.tag.split(":")
          const serviceProvider = splittedTag[0]
          const language = splittedTag[1]
          const domain = splittedTag[2]
          const version = splittedTag[3]

          const parameters: IModelParameter[] = []
          if (singleConfig.parameters.enableSplitToSentences) {
            parameters.push({
              name: 'enableSplitToSentences',
              type: singleConfig.parameters.enableSplitToSentences.type,
              values: singleConfig.parameters.enableSplitToSentences.values,
            })
          }
          if (singleConfig.parameters.enableTc) {
            parameters.push({
              name: 'enableTc',
              type: singleConfig.parameters.enableTc.type,
              values: singleConfig.parameters.enableTc.values,
            })
          }

          const languageExists = language in automaticPunctuationDictionary;
          if (!languageExists) {
            automaticPunctuationDictionary[language] = {}
          }

          const domainExists = domain in automaticPunctuationDictionary[language]
          if (!domainExists) {
            automaticPunctuationDictionary[language][domain] = {}
          }

          const versionExists = version in automaticPunctuationDictionary[language][domain]
          if (!versionExists) {
            automaticPunctuationDictionary[language][domain][version] = {
              features: singleConfig.features,
              parameters,
              serviceProvider
            }
            return;
          }

          throw new Error(`There should not be two same versions of pc tag: ${singleConfig.tag}`)
        })
        break;
      case Task.rep: //TODO
      configOptions.forEach(singleConfig => {
          const splittedTag = singleConfig.tag.split(":")
          const serviceProvider = splittedTag[0]
          const language = splittedTag[1]
          const domain = splittedTag[2]
          const version = splittedTag[3]

          const parameters: IModelParameter[] = []
          
          //todo
          /*if (singleConfig.parameters.enableSplitToSentences) {
            parameters.push({
              name: 'replacementsList',
              type: singleConfig.parameters.enableSplitToSentences.type,
              defaultValue: singleConfig.parameters.enableSplitToSentences.defaultValue,
              values: singleConfig.parameters.enableSplitToSentences.defaultValue,
            })
          }*/

          const languageExists = language in automaticReplacementDictionary;
          if (!languageExists) {
            automaticReplacementDictionary[language] = {}
          }

          const domainExists = domain in automaticReplacementDictionary[language]
          if (!domainExists) {
            automaticReplacementDictionary[language][domain] = {}
          }

          const versionExists = version in automaticReplacementDictionary[language][domain]
          if (!versionExists) {
            automaticReplacementDictionary[language][domain][version] = {
              features: singleConfig.features,
              parameters,
              serviceProvider
            }
            return;
          }

          throw new Error(`There should not be two same versions of rep tag: ${singleConfig.tag}`)
        })
        break;
      case Task.tc:
        configOptions.forEach(singleConfig => {
          const splittedTag = singleConfig.tag.split(":")
          const serviceProvider = splittedTag[0]
          const language = splittedTag[1]
          const domain = splittedTag[2]
          const version = splittedTag[3]

          const parameters: IModelParameter[] = []

          const languageExists = language in automaticTruecasingDictionary;
          if (!languageExists) {
            automaticTruecasingDictionary[language] = {}
          }

          const domainExists = domain in automaticTruecasingDictionary[language]
          if (!domainExists) {
            automaticTruecasingDictionary[language][domain] = {}
          }

          const versionExists = version in automaticTruecasingDictionary[language][domain]
          if (!versionExists) {
            automaticTruecasingDictionary[language][domain][version] = {
              features: singleConfig.features,
              parameters,
              serviceProvider
            }
            return;
          }

          throw new Error(`There should not be two same versions of tc tag: ${singleConfig.tag}`)
        })
        break;
    }
  })

  return {
    configurationDictionary,
    automaticPunctuationDictionary,
    automaticTruecasingDictionary,
    inverseTextNormalizationDictionary,
    automaticReplacementDictionary,
  };
}

export const mockConfig = (): ISttPipelines => {
  return {
    "KALDI": {
      "sl-SI": {
        "COL": {
          "21.12": {
            "features": [
              "onlineAsr"
            ],
            "parameters": [
              {
                "name": "enableUnks",
                "type": "Boolean",
                "values": [false]
              },
              {
                "name": "enableInterims",
                "type": "Boolean",
                "values": [false]
              }
            ]
          }
        },
        "LAW": {
          "20210923-2000": {
            "features": [
              "onlineAsr"
            ],
            "parameters": [
              {
                "name": "enableUnks",
                "type": "Boolean",
                "values": [false]
              },
              {
                "name": "enableInterims",
                "type": "Boolean",
                "values": [false]
              }
            ]
          }
        },
        "MED": {
          "22.06": {
            "features": [
              "onlineAsr",
              "dictatedCommands",
              "dictatedPunctuations"
            ],
            "parameters": [
              {
                "name": "enableUnks",
                "type": "Boolean",
                "values": [false]
              },
              {
                "name": "enableInterims",
                "type": "Boolean",
                "values": [false]
              }
            ]
          }
        }
      }
    },
    "GOOGLE": {
      "en-US": {
        "default": {
          "latest": {
            "features": [
              "onlineAsr"
            ],
            "parameters": [
              {
                "name": "enableUnks",
                "type": "Boolean",
                "values": [false]
              },
              {
                "name": "enableInterims",
                "type": "Boolean",
                "values": [false]
              }
            ]
          }
        },
        "video": {
          "latest": {
            "features": [
              "onlineAsr"
            ],
            "parameters": [
              {
                "name": "enableUnks",
                "type": "Boolean",
                "values": [false]
              },
              {
                "name": "enableInterims",
                "type": "Boolean",
                "values": [false]
              }
            ]
          }
        },
        "phone_call": {
          "latest": {
            "features": [
              "onlineAsr"
            ],
            "parameters": [
              {
                "name": "enableUnks",
                "type": "Boolean",
                "values": [false]
              },
              {
                "name": "enableInterims",
                "type": "Boolean",
                "values": [false]
              }
            ]
          }
        }
      },
      "sl-SI": {
        "default": {
          "latest": {
            "features": [
              "onlineAsr"
            ],
            "parameters": [
              {
                "name": "enableUnks",
                "type": "Boolean",
                "values": [false]
              },
              {
                "name": "enableInterims",
                "type": "Boolean",
                "values": [false]
              }
            ]
          }
        }
      }
    },
    "RIVA": {
      "sl-SI": {
        "DEFAULT": {
          "sl-SI-conformer_bpe_l_v7_val_wer=0.01566_20221123_greedy": {
            "features": [
              "onlineAsr"
            ],
            "parameters": [
              {
                "name": "enableUnks",
                "type": "Boolean",
                "values": [false]
              },
              {
                "name": "enableInterims",
                "type": "Boolean",
                "values": [false]
              }
            ]
          }
        }
      }
    }
  }
}