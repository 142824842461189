import { Dispatch } from 'react';

export interface SettingsPopupProps {
  handleCloseSettings: () => void;
  show: boolean;
  fileUploadIsInProgress?: boolean;
}

export interface ITranscriptOptions {
  language: string;
  model: string;
  endpointing: string;
  punctuation: boolean;
  capitalize: boolean;
  normalize: boolean;
  dictation: boolean;
}

export interface ITranslationOptions {
  language: string;
  interimResults: string;
}

export interface ISpeechOptions {
  language: string;
  voice: string;
}

export interface IModelMetadata {
  workers?: {
    available: number,
    active: number,
  },
  info?: {
    framework: string,
    am: string,
    lx: string,
    lm: string,
    src: string,
  },
  features?: {
    transcription: {
      casing: string,
      form: string,
    }
  }
}

export interface IModel {
  code: string;
  isAllowed: boolean;
  isAvailable: boolean;
  isRealtime: boolean;
  diarizationSupport: boolean;
  dictationCommandsSupport: boolean;
  metadata?: IModelMetadata;
}

export interface IDomain {
  isAllowed: boolean;
  code: string;
  models: IModel[];
}
export interface ILanguage {
  code: string;
  domains: IDomain[];
  isAllowed: boolean;
}
export interface IFramework {
  code: string;
  languages: ILanguage[];
  isAllowed: boolean;
}
export interface ISttStatus {
  frameworks: IFramework[]
}
export interface clientLang {
  languageCode: string;
  available: boolean;
}

export enum DictStatesEnum {
  NOT_RUNNING = 'NOT_RUNNING',
  RUNNING = 'RUNNING',
  FAILED = 'FAILED',
}

export interface DictProps {
  version: string | null;
  domain: string | null;
  language: string | null;
  isModelUpdatable: boolean | undefined;
  isModelUpdating: boolean | undefined;
}

//Reimplement
export enum Framework {
  KALDI = "KALDI",
  VOSK = "VOSK",
  RIVA = "RIVA",
  NEMO = "NEMO",
  GOOGLE = "GOOGLE",
}

interface IPonctuation {
  enabled: {
    isAllowed: boolean,
    value: boolean,
  },
  model: {
    isAllowed: boolean,
    value: string,
  },
  punctuateInterims: {
    isAllowed: boolean,
    value: boolean,
  },
  enableRealFinals: {
    isAllowed: boolean,
    value: boolean,
  }
}
interface IDenormalization {
  isAllowed: boolean,
  value: boolean,
}
interface ITranslation {
  enabled: {
    isAllowed: boolean,
    value: boolean,
  },
  language: {
    isAllowed: boolean,
    value: string,
  }
}
interface INlpConfig {
  punctuation: IPonctuation;
  enableDenormalization: IDenormalization;
  translation: ITranslation;
  enableTruecasing: {
    isAllowed: boolean,
    value: boolean
  },
}
interface ISttConfig {
  framework: {
    isAllowed: boolean,
    value: Framework | undefined
  },
  language: {
    isAllowed: boolean,
    value: string,
  },
  domain: {
    isAllowed: boolean,
    value: string,
  },
  model: {
    isAllowed: boolean,
    value: string,
  },
  enableInterimTranscripts: {
    isAllowed: boolean,
    value: boolean,
  },
  enableSd: {
    isAllowed: boolean,
    value: boolean,
  },
  enableDictatedPunctuations: {
    isAllowed: boolean,
    value: boolean,
  },
  enableDictatedCommands: {
    isAllowed: boolean,
    value: boolean,
  },
  enableUnks: {
    isAllowed: boolean,
    value: boolean,
  }
}

//Not used currently
interface ITtsConfig {
  modifiable: boolean;
}
export interface IConfig {
  stt: ISttConfig;
  nlp: INlpConfig;
  //ttsConfig: ITtsConfig; reserved for future use.
}



interface ISttConfigPatch {
  framework?: {
    isAllowed?: boolean,
    value?: Framework | undefined
  },
  language?: {
    isAllowed?: boolean,
    value?: string,
  },
  domain?: {
    isAllowed?: boolean,
    value?: string,
  },
  model?: {
    isAllowed?: boolean,
    value?: string,
  },
  enableInterimTranscripts?: {
    isAllowed?: boolean,
    value?: boolean,
  },
  enableSd?: {
    isAllowed?: boolean,
    value?: boolean,
  },
  enableDictatedPunctuations?: {
    isAllowed?: boolean,
    value?: boolean,
  },
  enableDictatedCommands?: {
    isAllowed?: boolean,
    value?: boolean,
  },
  enableUnks?: {
    isAllowed?: boolean,
    value?: boolean,
  }
}

interface IPonctuationPatch {
  enabled?: {
    isAllowed?: boolean,
    value?: boolean,
  },
  model?: {
    isAllowed?: boolean,
    value?: string,
  },
  punctuateInterims?: {
    isAllowed?: boolean,
    value?: boolean,
  },
  enableRealFinals?: {
    isAllowed?: boolean,
    value?: boolean,
  }
}

interface IDenormalizationPatch {
  isAllowed?: boolean,
  value?: boolean,
}
interface ITranslationPatch {
  enabled?: {
    isAllowed?: boolean,
    value?: boolean,
  },
  language?: {
    isAllowed?: boolean,
    value?: string,
  }
}
interface INlpConfigPatch {
  punctuation?: IPonctuationPatch;
  enableDenormalization?: IDenormalizationPatch;
  translation?: ITranslationPatch;
  enableTruecasing?: {
    isAllowed?: boolean,
    value?: boolean
  },
}
export interface IConfigPatch {
  stt?: ISttConfigPatch;
  nlp?: INlpConfigPatch;
}


interface IConfigParameter {
  type: string;
  values?: unknown[];
}
interface IConfigParameters {
  enableUnks?: IConfigParameter;
  enableInterims?: IConfigParameter;
  enableSd?: IConfigParameter;
  enableSplitToSentences?: IConfigParameter;
  enableTc?: IConfigParameter;
}
export interface IConfigOptions {
  tag: string;
  features: string[];
  parameters: IConfigParameters;
}
interface IConfigurationTask {
  task: string;
  configOptions: IConfigOptions[]
}

interface IRecordingSelectedConfigs {
  tag: string;
  parameters: {
    enableSd?: boolean,
    enableInterims?: boolean,
    enableUnks?: boolean,
    enableSplitToSentences?: boolean,
  }
}
export interface IRecordingConfigurationTask {
  task: string;
  config: IRecordingSelectedConfigs
}

interface IConfigParametersSend {
  enableUnks?: boolean;
  enableInterims?: boolean;
  enableSd?: boolean;
  enableSplitToSentences?: boolean;
}
interface IConfigurationTaskSend {
  task: string;
  config: IConfigOptionSend
}

export interface IConfigOptionSend {
  tag: string;
  parameters: IConfigParametersSend;
}

export type IConfiguration = IConfigurationTask[]
export type IRecordingConfiguration = IRecordingConfigurationTask[]
export type IConfigurationSend = IConfigurationTaskSend[]

interface IRecordingDetails {
  id: number,
  recordedMs: number,
  processedMs: number,
  pipeline: IRecordingConfiguration,
  source: string,
  createdAt: string,
  isDiscarded: boolean,
}

export type IRecordingsArray = IRecordingDetails[]

interface GenericDict<T> {
  [key: string]: T
}

export interface IModelParameter {
  name: string;
  type: string;
  values: unknown[] | undefined;
}
export interface ILocalModel {
  features: string[],
  parameters: IModelParameter[]
}

export interface ILocalModelTTT {
  serviceProvider: string,
  features: string[],
  parameters: IModelParameter[]
}

export type ISttPipelines = GenericDict<GenericDict<GenericDict<GenericDict<ILocalModel>>>>
export type ITttPipelines = GenericDict<GenericDict<GenericDict<ILocalModelTTT>>>

export enum Task {
  asr = "ASR", //Automatic Speech Recognition
  pc = "NLP_pc", //Automatic Punctuation
  tc = "NLP_tc", //Automatic Truecasing
  itn = "NLP_itn", //Inverse Text Normalization
  rep = "NLP_rep", //Automatic Replacement
  ac = "NLP_ac", //Automatic Replacement
}