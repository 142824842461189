import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  DictProps,
  DictStatesEnum,
  SettingsPopupProps,
  IModelMetadata,
  ITttPipelines,
  IConfiguration,
  Task,
} from './ISettings';
import { IActiveConfiguration, IAutomaticPunctuationModel, IInverseTextNormalizator, IPipelineModel, IPunctuator, ITrueCaser, UserRoleEnums } from '../../redux/store/IStore';
import {
  clearLocalStorage,
  GrantTypesEnums,
  keycloakTokensResponse,
  removeTokens,
  requestKeycloakTokens,
  setTokens,
} from '../../api/AuthenticationService';
import '../../styles/css/settingspopover.css';
import { AnimatePresence, motion } from 'framer-motion';
import {
  DictDesynchedIcon,
  DictEditIcon,
  DictSynchedIcon,
  DictSyncingIcon0,
  DictSyncingIcon1,
  DictSyncingIcon2,
  DictSyncingIcon3,
  FailedSyncingIcon,
} from '../Icons/RefreshDicIcons';
import useInterval from '../../hooks/useInterval';
import {
  getNewWordsStatus,
} from '../../api/DictionaryService';
import { TagOperation, getStages, putTagOperationStatus } from '../../api/configurationService';
import AddLabelsButton from './AddLabelsButton';
import { jwtDecode } from 'jwt-decode';
import { JWTDecoded } from '../Dashboard/IDashboard';
import { ClearIcon } from '../Icons/ClearIcon';
import { useNavigate } from 'react-router-dom';
import { config } from '../../config';
import { useSnackbar } from 'notistack';
import './settings.css';
import { transformConfiguration } from './helpers';
import { getSessionRecordings, getSessions } from '../../api/SessionsService';
import { IUserQuota, getUserData, transformConsumedAndLimitToReadable, transformConsumedToReadable, translateChunkKey } from '../../api/UserService';
import OptionSelector from './OptionSelector';
import { convertLocalModelToPipelineModel, getAvailableSettings, getDomains, getLanguages, getVersions } from './settingsHelpers';
import useKeycloak from '../../hooks/useKeycloak';
import { keycloak } from '../../providers/KeycloakAuthProvider';
import ConsumptionBar from './ConsumptionBar';
import useModelStatus from '../../hooks/useModelStatus';
import TextField from '@mui/material/TextField';
import LinearProgress from '@mui/material/LinearProgress';
import Tooltip from '@mui/material/Tooltip';
import Checkbox from '@mui/material/Checkbox';
import InputAdornment from '@mui/material/InputAdornment';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { setActiveConfiguration, setActiveInverseTextNormalizator, setActivePunctuator, setActiveTruecaser, setConfiguration, setConsumptionModal, setDashboardPagination, setUser } from '../../redux/features/app/app';

const settingsVariants = {
  visible: {
    x: 0,
    transition: { ease: 'easeInOut', duration: 0.35 },
  },
  hidden: {
    x: -500,
    transition: { ease: 'easeInOut', duration: 0.35 },
  },
};

const {
  keycloakUrl,
  publicUrl,
  keycloakClientId,
  keycloakAuthReam,
} = config;

const SettingsDrawer: React.FC<SettingsPopupProps> = ({
  handleCloseSettings,
  show,
  fileUploadIsInProgress,
}) => {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const user = useAppSelector(state => state.app.user);
  const [loadingCfg, setLoadingCfg] = useState(true);
  const [configError, setConfigError] = useState(false);

  const testCoutner = useRef<number>(0);

  const isUserFrameworkChanger = user && user.userRoles.includes(UserRoleEnums.FRAMEWORK_CHANGER)
  const isAdmin = user &&
    user.userRoles.includes(UserRoleEnums.SESSION_READ_DISCARDED) &&
    user.userRoles.includes(UserRoleEnums.SESSION_READ_OTHER) &&
    user.userRoles.includes(UserRoleEnums.SESSION_WRITE_OTHER) &&
    user.userRoles.includes(UserRoleEnums.USER_READ_OTHER)

  const activeConfiguration = useAppSelector(store => store.app.activeConfiguration)
  const activePunctuator = useAppSelector(store => store.app.activePunctuator)
  const activeInverseTextNormalizator = useAppSelector(store => store.app.activeInverseTextNormalizator)
  const activeTrueCaser = useAppSelector(store => store.app.activeTrueCaser)
  const configuration = useAppSelector(store => store.app.configuration)

  const activeQuota = useAppSelector(state => state.app.activeQuota);

  const [quota, setQuota] = useState<IUserQuota | null>(null)
  const [authFailed, setAuthFailed] = useState<boolean | string>(false);
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [allowModelUpdating, setAllowModelUpdating] = useState<boolean>(true);
  const [modelMetadata, setModelMetadata] = useState<IModelMetadata | undefined>(undefined);
  const [modelInfoModalVisible, setModelInfoVisible] = useState<boolean>(false); //null when info is not available, undefined when modal should be hidden
  const settingsRef = useRef(null);

  const onRegenStatusCallback = useCallback(
    (status) => {
      setAllowModelUpdating(status ? status : false);
    },
    [setAllowModelUpdating]
  );

  const resetState = () => {
    setAuthFailed(false);
    setUsername('');
    setPassword('');
  };

  const [possibleFrameworks, setPossibleFrameworks] = useState<string[]>([])
  const [possibleLanguages, setPossibleLanguages] = useState<string[]>([])
  const [possibleDomains, setPossibleDomains] = useState<string[]>([])
  const [possibleVersions, setPossibleVersions] = useState<IPipelineModel[]>([])
  const [automaticPunctuation, setAutomaticPunctuation] = useState<ITttPipelines | null>(null)
  const [automaticTruecasing, setAutomaticTruecasing] = useState<ITttPipelines | null>(null)
  const [inverseTextNormalization, setInverseTextNormalization] = useState<ITttPipelines | null>(null)
  const [automaticReplacement, setAutomaticReplacement] = useState<ITttPipelines | null>(null)
  //This method is used for fetching configuration on initial load
  //It differs by looking at local storage values and last recording pipeline.
  const fetchInitialConfiguration = async () => {
    if (!isAuthenticated) {
      setLoadingCfg(false)
      return;
    }

    setLoadingCfg(true)

    let stages: IConfiguration | null = null
    try {
      stages = (await getStages()).data
    } catch (error) {
      const mappedError = error as any;
      //TODO: this needs to be improved, but ok for now.
      if (mappedError.response.status === 403) {
        enqueueSnackbar("Za uporabo izbranega modela nimate ustreznih pravic.", { variant: 'error' })
      } else if (mappedError.response.status === 404) {
        enqueueSnackbar("Izbrani model ne obstaja.", { variant: 'error' })
      } else {
        enqueueSnackbar(`Prišlo je do napake. Prosimo kontaktirajte tehnično podporo. Koda napake: ${mappedError.response.data.id}`, { variant: "autoCopyError", autoHideDuration: null })
      }
      setLoadingCfg(false)
      return;
    }

    const {
      configurationDictionary,
      automaticPunctuationDictionary,
      automaticTruecasingDictionary,
      inverseTextNormalizationDictionary,
      automaticReplacementDictionary,
    } = transformConfiguration(stages)

    const itnLocal = localStorage.getItem("itn")
    const asrLocal = localStorage.getItem("asr")
    const pcLocal = localStorage.getItem("pc")
    const tcLocal = localStorage.getItem("tc")

    const asrLocalParsed = asrLocal ? JSON.parse(asrLocal) as IActiveConfiguration : null

    const doesLocalAsrWithoutServiceProviderExists = () => {
      let exists = false;
      Object.keys(configurationDictionary).forEach(framework => {
        if (
          asrLocalParsed &&
          asrLocalParsed.language &&
          asrLocalParsed.domain &&
          asrLocalParsed.model &&
          configurationDictionary[framework] &&
          configurationDictionary[framework][asrLocalParsed.language] &&
          configurationDictionary[framework][asrLocalParsed.language][asrLocalParsed.domain] &&
          configurationDictionary[framework][asrLocalParsed.language][asrLocalParsed.domain][asrLocalParsed.model.name]
        ) {
          exists = true
        }
      });
      return exists;
    }
    if (
      asrLocalParsed &&
      isUserFrameworkChanger &&
      asrLocalParsed.serviceProvider &&
      asrLocalParsed.language &&
      asrLocalParsed.domain &&
      asrLocalParsed.model &&
      configurationDictionary[asrLocalParsed.serviceProvider] &&
      configurationDictionary[asrLocalParsed.serviceProvider][asrLocalParsed.language] &&
      configurationDictionary[asrLocalParsed.serviceProvider][asrLocalParsed.language][asrLocalParsed.domain] &&
      configurationDictionary[asrLocalParsed.serviceProvider][asrLocalParsed.language][asrLocalParsed.domain][asrLocalParsed.model.name]) {

      const serviceProvider = asrLocalParsed.serviceProvider
      const language = asrLocalParsed.language
      const domain = asrLocalParsed.domain

      const {
        frameworks,
        languages,
        domains,
        versions,
      } = getAvailableSettings(isUserFrameworkChanger, configurationDictionary, serviceProvider, language, domain);

      const itnLocalParsed = itnLocal ? JSON.parse(itnLocal) as IInverseTextNormalizator : null
      const pcLocalParsed = pcLocal ? JSON.parse(pcLocal) as IPunctuator : null
      const tcLocalParsed = tcLocal ? JSON.parse(tcLocal) as ITrueCaser : null
      
      //If local asr language is same as local pc
      if (
        itnLocalParsed &&
        itnLocalParsed.language &&
        itnLocalParsed.domain &&
        itnLocalParsed.model &&
        inverseTextNormalizationDictionary[itnLocalParsed.language] &&
        inverseTextNormalizationDictionary[itnLocalParsed.language][itnLocalParsed.domain] &&
        inverseTextNormalizationDictionary[itnLocalParsed.language][itnLocalParsed.domain][itnLocalParsed.model.name]
      ) {
        dispatch(setActiveInverseTextNormalizator(itnLocalParsed))
      } else {
        dispatch(setActiveTruecaser(handleTcOnModelChange(asrLocalParsed.language, asrLocalParsed.domain, automaticTruecasingDictionary)))
      }

      //If local asr language is same as local pc
      if (
        pcLocalParsed &&
        pcLocalParsed.language &&
        pcLocalParsed.domain &&
        pcLocalParsed.model &&
        automaticPunctuationDictionary[pcLocalParsed.language] &&
        automaticPunctuationDictionary[pcLocalParsed.language][pcLocalParsed.domain] &&
        automaticPunctuationDictionary[pcLocalParsed.language][pcLocalParsed.domain][pcLocalParsed.model.name]
      ) {
        dispatch(setActivePunctuator(pcLocalParsed))
      } else {
        dispatch(setActivePunctuator(handlePcOnModelChange(asrLocalParsed.language, asrLocalParsed.domain, automaticPunctuationDictionary)))
        //select first punctuator if available
      }

      //If local asr language is same as local pc
      if (
        tcLocalParsed &&
        tcLocalParsed.language &&
        tcLocalParsed.domain &&
        tcLocalParsed.model &&
        automaticTruecasingDictionary[tcLocalParsed.language] &&
        automaticTruecasingDictionary[tcLocalParsed.language][tcLocalParsed.domain] &&
        automaticTruecasingDictionary[tcLocalParsed.language][tcLocalParsed.domain][tcLocalParsed.model.name]
      ) {
        dispatch(setActiveTruecaser(tcLocalParsed))
      } else {
        dispatch(setActiveInverseTextNormalizator(handleItnOnModelChange(asrLocalParsed.language, asrLocalParsed.domain, inverseTextNormalizationDictionary)))
      }

      dispatch(setActiveConfiguration(asrLocalParsed))
      dispatch(setConfiguration(configurationDictionary))
      setAutomaticPunctuation(automaticPunctuationDictionary)
      setAutomaticTruecasing(automaticTruecasingDictionary)
      setInverseTextNormalization(inverseTextNormalizationDictionary)
      setAutomaticReplacement(automaticReplacementDictionary)
      setPossibleFrameworks(Array.from(frameworks))
      setPossibleLanguages(Array.from(languages))
      setPossibleDomains(Array.from(domains))
      setPossibleVersions(Array.from(versions))
      setLoadingCfg(false)
      return;
    } else if (asrLocalParsed && doesLocalAsrWithoutServiceProviderExists()) {
      const serviceProvider = asrLocalParsed.serviceProvider
      const language = asrLocalParsed.language
      const domain = asrLocalParsed.domain

      const {
        languages,
        domains,
        versions,
      } = getAvailableSettings(isUserFrameworkChanger, configurationDictionary, serviceProvider, language, domain);

      const itnLocalParsed = itnLocal ? JSON.parse(itnLocal) as IInverseTextNormalizator : null
      const pcLocalParsed = pcLocal ? JSON.parse(pcLocal) as IPunctuator : null
      const tcLocalParsed = tcLocal ? JSON.parse(tcLocal) as ITrueCaser : null

      //If local asr language is same as local pc
      if (
        itnLocalParsed &&
        itnLocalParsed.language &&
        itnLocalParsed.domain &&
        itnLocalParsed.model &&
        inverseTextNormalizationDictionary[itnLocalParsed.language] &&
        inverseTextNormalizationDictionary[itnLocalParsed.language][itnLocalParsed.domain] &&
        inverseTextNormalizationDictionary[itnLocalParsed.language][itnLocalParsed.domain][itnLocalParsed.model.name]
      ) {
        dispatch(setActiveInverseTextNormalizator(itnLocalParsed))
      } else {
        dispatch(setActiveTruecaser(handleTcOnModelChange(asrLocalParsed.language ? asrLocalParsed.language : undefined, asrLocalParsed.domain ? asrLocalParsed.domain : undefined, automaticTruecasingDictionary)))
      }

      //If local asr language is same as local pc
      if (
        pcLocalParsed &&
        pcLocalParsed.language &&
        pcLocalParsed.domain &&
        pcLocalParsed.model &&
        automaticPunctuationDictionary[pcLocalParsed.language] &&
        automaticPunctuationDictionary[pcLocalParsed.language][pcLocalParsed.domain] &&
        automaticPunctuationDictionary[pcLocalParsed.language][pcLocalParsed.domain][pcLocalParsed.model.name]
      ) {
        dispatch(setActivePunctuator(pcLocalParsed))
      } else {
        dispatch(setActivePunctuator(handlePcOnModelChange(asrLocalParsed.language ? asrLocalParsed.language : undefined, asrLocalParsed.domain ? asrLocalParsed.domain : undefined, automaticPunctuationDictionary)))
        //select first punctuator if available
      }

      //If local asr language is same as local pc
      if (
        tcLocalParsed &&
        tcLocalParsed.language &&
        tcLocalParsed.domain &&
        tcLocalParsed.model &&
        automaticTruecasingDictionary[tcLocalParsed.language] &&
        automaticTruecasingDictionary[tcLocalParsed.language][tcLocalParsed.domain] &&
        automaticTruecasingDictionary[tcLocalParsed.language][tcLocalParsed.domain][tcLocalParsed.model.name]
      ) {
        dispatch(setActiveTruecaser(tcLocalParsed))
      } else {
        dispatch(setActiveInverseTextNormalizator(handleItnOnModelChange(asrLocalParsed.language ? asrLocalParsed.language : undefined, asrLocalParsed.domain ? asrLocalParsed.domain : undefined, inverseTextNormalizationDictionary)))
      }

      dispatch(setActiveConfiguration(asrLocalParsed))
      dispatch(setConfiguration(configurationDictionary))
      setAutomaticPunctuation(automaticPunctuationDictionary)
      setAutomaticTruecasing(automaticTruecasingDictionary)
      setInverseTextNormalization(inverseTextNormalizationDictionary)
      setAutomaticReplacement(automaticReplacementDictionary)
      setPossibleLanguages(Array.from(languages))
      setPossibleDomains(Array.from(domains))
      setPossibleVersions(Array.from(versions))
      setLoadingCfg(false)
      return;
    }

    try {
      const latestSession = await getSessions(undefined, 1, new URLSearchParams(), { columnName: 'null', type: "ASC" });

      if (latestSession.length > 0) {
        const recordings = await getSessionRecordings(latestSession[0].content.id)
        const latestRecording = recordings.data.sort((a, b) => new Date(b.createdAt).getMilliseconds() - new Date(a.createdAt).getMilliseconds())[0]

        //This is probably not needed as ASr stage will always be present, but just to make sure.
        const asrIndex = latestRecording.pipeline.findIndex(pipe => pipe.task === Task.asr)

        if (asrIndex >= 0) {
          const asrStage = latestRecording.pipeline[asrIndex]
          const splittedAsrTag = asrStage.config.tag.split(":")
          const latestRecordingServiceProvider = splittedAsrTag[0]
          const latestRecordingLanguage = splittedAsrTag[1]
          const latestRecordingDomain = splittedAsrTag[2]
          const latestRecordingModel = splittedAsrTag[3]

          if (
            configurationDictionary[latestRecordingServiceProvider] &&
            configurationDictionary[latestRecordingServiceProvider][latestRecordingLanguage] &&
            configurationDictionary[latestRecordingServiceProvider][latestRecordingLanguage][latestRecordingDomain] &&
            configurationDictionary[latestRecordingServiceProvider][latestRecordingLanguage][latestRecordingDomain][latestRecordingModel]
          ) {
            const latestModel = configurationDictionary[latestRecordingServiceProvider][latestRecordingLanguage][latestRecordingDomain][latestRecordingModel]


            const {
              frameworks,
              languages,
              domains,
              versions,
            } = getAvailableSettings(isAdmin, configurationDictionary, latestRecordingServiceProvider, latestRecordingLanguage, latestRecordingDomain);

            dispatch(setActivePunctuator(handlePcOnModelChange(latestRecordingLanguage, latestRecordingDomain, automaticPunctuationDictionary)))
            dispatch(setActiveTruecaser(handleTcOnModelChange(latestRecordingLanguage, latestRecordingDomain, automaticTruecasingDictionary)))
            dispatch(setActiveInverseTextNormalizator(handleItnOnModelChange(latestRecordingLanguage, latestRecordingDomain, inverseTextNormalizationDictionary)))
            const convertedModel = convertLocalModelToPipelineModel(latestModel, latestRecordingModel, latestRecordingServiceProvider)

            //convertedModel.
            dispatch(setActiveConfiguration({
              serviceProvider: latestRecordingServiceProvider,
              language: latestRecordingLanguage,
              domain: latestRecordingDomain,
              model: convertedModel
            }))
            dispatch(setConfiguration(configurationDictionary))
            setAutomaticPunctuation(automaticPunctuationDictionary)
            setAutomaticTruecasing(automaticTruecasingDictionary)
            setInverseTextNormalization(inverseTextNormalizationDictionary)
            setAutomaticReplacement(automaticReplacementDictionary)
            setPossibleFrameworks(frameworks)
            setPossibleLanguages(languages)
            setPossibleDomains(domains)
            setPossibleVersions(versions)
            setLoadingCfg(false)

            return;
          }
        }
      }
    } catch (error) {
      console.log(error);
    }

    //No settings recovery mechanism worked. Move to intial settings. Maybe even call original method.
    //This is temporary, to advance the requested feature, maybe pass in already fetched stages as argument
    proccessConfiguration(stages);
    //await fetchConfiguration();
  }

  const proccessConfiguration = (stages: IConfiguration) => {
    const {
      configurationDictionary,
      automaticPunctuationDictionary,
      automaticTruecasingDictionary,
      inverseTextNormalizationDictionary,
      automaticReplacementDictionary,
    } = transformConfiguration(stages)


    if (Object.keys(configurationDictionary).length < 1) {
      setLoadingCfg(false)
      return;
    }

    let {
      serviceProvider,
      language,
      domain,
      model
    } = activeConfiguration

    let mappedServiceProvider = serviceProvider
    let mappedLanguage = language
    let mappedDomain = domain
    let mappedModel = model


    //You stayed here on inital fetch. Continue here.
    if ((!mappedServiceProvider && isAdmin) || !mappedLanguage || !mappedDomain || !mappedModel) {
      const serviceProviders = Object.entries(configurationDictionary)
      if (serviceProviders.length > 0) {
        mappedServiceProvider = serviceProviders[0][0]
        const languages = Object.entries(serviceProviders[0][1])
        if (languages.length > 0) {
          mappedLanguage = languages[0][0]
          const domains = Object.entries(languages[0][1])
          if (domains.length > 0) {
            mappedDomain = domains[0][0]
            const models = Object.entries(domains[0][1])
            if (models.length > 0) {
              mappedModel = convertLocalModelToPipelineModel(models[0][1], models[0][0], mappedServiceProvider)
            }
          }
        }
      }
    }

    const {
      frameworks,
      languages,
      domains,
      versions,
    } = getAvailableSettings(isAdmin, configurationDictionary, mappedServiceProvider, mappedLanguage, mappedDomain);

    dispatch(setActiveConfiguration({
      serviceProvider: mappedServiceProvider,
      language: mappedLanguage,
      domain: mappedDomain,
      model: mappedModel,
    }))


    dispatch(setActivePunctuator(handlePcOnModelChange(mappedLanguage ? mappedLanguage : undefined, mappedDomain ? mappedDomain : undefined, automaticPunctuationDictionary)))
    dispatch(setActiveTruecaser(handleTcOnModelChange(mappedLanguage ? mappedLanguage : undefined, mappedDomain ? mappedDomain : undefined, automaticTruecasingDictionary)))
    dispatch(setActiveInverseTextNormalizator(handleItnOnModelChange(mappedLanguage ? mappedLanguage : undefined, mappedDomain ? mappedDomain : undefined, inverseTextNormalizationDictionary)))

    setPossibleFrameworks(Array.from(frameworks))
    setPossibleLanguages(Array.from(languages))
    setPossibleDomains(Array.from(domains))
    setPossibleVersions(Array.from(versions))
    dispatch(setConfiguration(configurationDictionary))
    setAutomaticPunctuation(automaticPunctuationDictionary)
    setAutomaticTruecasing(automaticTruecasingDictionary)
    setInverseTextNormalization(inverseTextNormalizationDictionary)
    setAutomaticReplacement(automaticReplacementDictionary)
    setLoadingCfg(false)
  }

  const {
    isAuthenticated
  } = useKeycloak();

  const {
    getModelStatus
  } = useModelStatus();

  //This method is used to prefetch data.
  const prefetchConfiguration = useCallback(async () => {
    if (!isAuthenticated || !user) {
      return;
    }

    try {
      const userData = await getUserData();
      dispatch(setUser({ ...user, group: userData.data.group }))
    } catch (error) {
      //TODO: Handle user error
      return;
    }

    let stages: IConfiguration | undefined;
    try {
      stages = (await getStages()).data
    } catch (error) {
      const mappedError = error as any
      if (mappedError.response.status === 403) {
        enqueueSnackbar("Za uporabo izbranega modela nimate ustreznih pravic.", { variant: 'error' })
      } else if (mappedError.response.status === 404) {
        enqueueSnackbar("Izbrani model ne obstaja.", { variant: 'error' })
      } else {
        enqueueSnackbar(`Prišlo je do napake. Prosimo kontaktirajte tehnično podporo. Koda napake: ${mappedError.response.data.id}`, { variant: "autoCopyError", autoHideDuration: null })
      }
      return;
    }

    if (!stages) return;

    if (!activeConfiguration.serviceProvider || !activeConfiguration.language || !activeConfiguration.domain || !activeConfiguration.model) {
      setPrefetchedConfiguration(stages);
      return;
    }

    const asrStage = stages.filter(stage => stage.task === "ASR")
    if (asrStage.length < 1) {
      setPrefetchedConfiguration(stages)
      return;
    }

    const tag = `${activeConfiguration.serviceProvider}:${activeConfiguration.language}:${activeConfiguration.domain}:${activeConfiguration.model.name}`
    const modelsWithTag = asrStage[0].configOptions.filter(model => model.tag === tag)
    if (modelsWithTag.length < 1) {
      setPrefetchedConfiguration(stages)
      return;
    }

    try {
      const status = await getModelStatus()
      const newWordsStatus = await getNewWordsStatus(`${activeConfiguration.language}%3A${activeConfiguration.domain}%3A${activeConfiguration.model.name}`);
      dispatch(setActiveConfiguration({
        ...activeConfiguration,
        model: {
          ...activeConfiguration.model,
          isUpdatable: status.isUpdatable && newWordsStatus,
          isUpdating: status.isUpdating
        }
      }))
    } catch (error) {
      dispatch(setActiveConfiguration({
        ...activeConfiguration,
        model: {
          ...activeConfiguration.model,
          isUpdatable: false,
          isUpdating: false
        }
      }))
    }


  }, [isAuthenticated, activeConfiguration])



  const [prefetchedConfiguration, setPrefetchedConfiguration] = useState<IConfiguration | null>(null);
  const afterLoginRef = useRef<boolean>(true);
  useEffect(() => {
    if (!afterLoginRef.current && prefetchedConfiguration) {
      proccessConfiguration(prefetchedConfiguration);
    }
  }, [prefetchedConfiguration])


  useEffect(() => {
    if (isAuthenticated) {
      if (afterLoginRef.current) {
        afterLoginRef.current = false;
        fetchInitialConfiguration();
      }
    } else {
      afterLoginRef.current = true;
    }
  }, [isAuthenticated])

  useEffect(() => {
    let timeout = setInterval(() => {
      prefetchConfiguration();
    }, 10000)

    return () => {
      if (timeout) {
        clearInterval(timeout)
      }
    }
  }, [activeConfiguration, user?.isAuthenticated, user?.username])

  /*useEffect(() => {
    const quotaWrapper = async () => {
      try {
        const quota = await getUserQuota()
        setQuota(quota.data)
      } catch (error) {
        console.log(error)
      }
    }
    quotaWrapper();
  }, [show])*/

  const showConsumptionModal = () => dispatch(setConsumptionModal({
    visible: true,
    template: undefined,
  }))


  const onSubmit = async (e: any) => {
    e.preventDefault();
    if (!user) {
      try {
        const tokens = await requestKeycloakTokens(username, password, GrantTypesEnums.PASSWORD);
        const data = tokens.data as keycloakTokensResponse;

        if (data) {
          setTokens({ access_token: data.access_token, refresh_token: data.refresh_token });

          const decodedTokenData: JWTDecoded = jwtDecode(data.access_token);

          setAuthFailed(false);
          if (!decodedTokenData.realm_access.roles.includes(UserRoleEnums.EDITOR_USER)) {
            removeTokens();
            dispatch(setUser(null));
            //dispatch(setRefetchTokens(null));
            setAuthFailed('Uporabniški račun nima pravic za uporabo True-Bar platforme');
            const homeBtns = document.getElementsByClassName('buttons_container')[0];
            homeBtns.classList.add('invalid-auth');
            setTimeout(() => {
              homeBtns.classList.remove('invalid-auth');
            }, 500);
            return;
          }

          const userData = await getUserData();

          dispatch(
            setUser({
              username,
              accessToken: data.access_token,
              refreshToken: data.refresh_token,
              isAuthenticated: true,
              userRoles: decodedTokenData.realm_access.roles,
              isEditorUser: decodedTokenData.realm_access.roles.includes(UserRoleEnums.EDITOR_USER),
              group: userData.data.group
            })
          );
          setUsername('')
          setPassword('')
          //dispatch(setRefetchTokens(data.expires_in));
        }
      } catch (e) {
        // console.log(e);
        removeTokens();
        dispatch(setUser(null));
        //dispatch(setRefetchTokens(null));
        setAuthFailed('Vneseni podatki so napačni');
        const homeBtns = document.getElementsByClassName('buttons_container')[0];
        homeBtns.classList.add('invalid-auth');
        setTimeout(() => {
          homeBtns.classList.remove('invalid-auth');
        }, 500);
      }

      return;
    }
  };

  const handleLogoutClick = () => {
    clearLocalStorage();
    resetState();
    keycloak.clearToken()
    if (user && isAuthenticated && user.idToken) {
      //keycloak.createLogoutUrl = () => `${keycloakUrl}/auth/realms/${keycloakAuthReam}/protocol/openid-connect/logout?id_token_hint=${user.idToken}&post_logout_redirect_uri=${publicUrl}&client_id=${keycloakClientId}&initiating_idp=${envConfig.keycloakIdpAlias}`
      keycloak.createLogoutUrl = () => `${keycloakUrl}/realms/${keycloakAuthReam}/protocol/openid-connect/logout?id_token_hint=${user.idToken}&post_logout_redirect_uri=${publicUrl}&client_id=${keycloakClientId}`
      /*batch(() => {
        dispatch(setRefetchTokens(null));
        dispatch(setUser(null));
        dispatch(setDashboardFilters([]));
  
        dispatch(setActiveConfiguration({ serviceProvider: null, language: null, domain: null, model: null }))
        dispatch(setActiveInverseTextNormalizator({ toggled: false, language: null, domain: null, model: null }))
        dispatch(setActiveTruecaser({ toggled: false, language: null, domain: null, model: null }))
        dispatch(setActivePunctuator({ toggled: false, language: null, domain: null, model: null }))
      });*/
      keycloak.logout()
    }
  };

  const closeModelInfoModal = () => {
    setModelMetadata(undefined);
    setModelInfoVisible(false);
  };

  const preventOnClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  const openModelInfoModal = () => {

    setModelInfoVisible(true);
  };

  const handleFrameworkChange = useCallback((serviceProvider: string) => {
    if (!isUserFrameworkChanger || !configuration || loadingCfg || serviceProvider === activeConfiguration.serviceProvider) return;

    const languages = getLanguages(true, configuration, serviceProvider)
    const selectedLanguage = languages[0]
    const domains = getDomains(true, configuration, serviceProvider, selectedLanguage);
    const selectedDomain = domains[0];
    const versions = getVersions(true, configuration, serviceProvider, selectedLanguage, selectedDomain)
    const selectedVersion = versions[0];

    if (selectedVersion.enableSd && activeConfiguration.model && activeConfiguration.model.enableSd) {
      selectedVersion.enableSd.value = activeConfiguration.model.enableSd.value
    }

    if (selectedVersion.enableInterims && activeConfiguration.model && activeConfiguration.model.enableInterims) {
      selectedVersion.enableInterims.value = activeConfiguration.model.enableInterims.value
    }

    setPossibleLanguages(languages)
    setPossibleDomains(domains)
    setPossibleVersions(versions)

    const inverseTextNormalizationModel = handleItnOnModelChange(selectedLanguage, selectedDomain)
    const punctuationModel = handlePcOnModelChange(selectedLanguage, selectedDomain)
    const truecaserModel = handleTcOnModelChange(selectedLanguage, selectedDomain)
    dispatch(setActiveConfiguration({ serviceProvider, language: selectedLanguage, domain: selectedDomain, model: selectedVersion }))
    dispatch(setActiveInverseTextNormalizator(inverseTextNormalizationModel))
    dispatch(setActivePunctuator(punctuationModel))
    dispatch(setActiveTruecaser(truecaserModel))
  }, [configuration, activeConfiguration, loadingCfg, isAdmin, activeTrueCaser, activePunctuator, activeInverseTextNormalizator])

  const handleLanguageChange = useCallback((selectedLanguage: string) => {
    if (!configuration || loadingCfg || selectedLanguage === activeConfiguration.language) return;

    let currentFramework: string | null = null
    if (activeConfiguration.serviceProvider) {
      currentFramework = possibleFrameworks.includes(activeConfiguration.serviceProvider) && isUserFrameworkChanger ? activeConfiguration.serviceProvider : null
    }

    const domains = getDomains(isUserFrameworkChanger, configuration, currentFramework, selectedLanguage)
    const selectedDomain = domains[0];
    const versions = getVersions(true, configuration, currentFramework, selectedLanguage, selectedDomain)
    const selectedVersion = versions[0];

    if (selectedVersion.enableSd && activeConfiguration.model && activeConfiguration.model.enableSd) {
      selectedVersion.enableSd.value = activeConfiguration.model.enableSd.value
    }

    if (selectedVersion.enableInterims && activeConfiguration.model && activeConfiguration.model.enableInterims) {
      selectedVersion.enableInterims.value = activeConfiguration.model.enableInterims.value
    }

    setPossibleDomains(domains)
    setPossibleVersions(versions)

    const inverseTextNormalizationModel = handleItnOnModelChange(selectedLanguage, selectedDomain)
    const punctuationModel = handlePcOnModelChange(selectedLanguage, selectedDomain)
    const truecaserModel = handleTcOnModelChange(selectedLanguage, selectedDomain)
    dispatch(setActiveConfiguration({ serviceProvider: currentFramework, language: selectedLanguage, domain: selectedDomain, model: selectedVersion }))
    dispatch(setActiveInverseTextNormalizator(inverseTextNormalizationModel))
    dispatch(setActivePunctuator(punctuationModel))
    dispatch(setActiveTruecaser(truecaserModel))
  }, [configuration, activeConfiguration, loadingCfg, user, activeTrueCaser, activePunctuator, activeInverseTextNormalizator]);

  /**
   * 
   * @param event updateActivePunctuator
updateActiveTrueCaser
updateActiveInverseTextNormalizer
   */
  const handleDomainChange = useCallback((selectedDomain: string) => {
    if (!configuration || loadingCfg || selectedDomain === activeConfiguration.domain) return;

    let currentFramework: string | null = null
    if (activeConfiguration.serviceProvider) {
      currentFramework = possibleFrameworks.includes(activeConfiguration.serviceProvider) && isUserFrameworkChanger ? activeConfiguration.serviceProvider : null
    }

    const versions = getVersions(isUserFrameworkChanger, configuration, currentFramework, activeConfiguration.language, selectedDomain)
    const selectedVersion = versions[0];

    if (selectedVersion.enableSd && activeConfiguration.model && activeConfiguration.model.enableSd) {
      selectedVersion.enableSd.value = activeConfiguration.model.enableSd.value
    }

    if (selectedVersion.enableInterims && activeConfiguration.model && activeConfiguration.model.enableInterims) {
      selectedVersion.enableInterims.value = activeConfiguration.model.enableInterims.value
    }

    setPossibleVersions(versions)

    const inverseTextNormalizationModel = handleItnOnModelChange(activeConfiguration.language ? activeConfiguration.language : undefined, selectedDomain)
    const punctuationModel = handlePcOnModelChange(activeConfiguration.language ? activeConfiguration.language : undefined, selectedDomain)
    const truecaserModel = handleTcOnModelChange(activeConfiguration.language ? activeConfiguration.language : undefined, selectedDomain)
    dispatch(setActiveConfiguration({ ...activeConfiguration, serviceProvider: currentFramework, domain: selectedDomain, model: selectedVersion }))
    dispatch(setActiveInverseTextNormalizator(inverseTextNormalizationModel))
    dispatch(setActivePunctuator(punctuationModel))
    dispatch(setActiveTruecaser(truecaserModel))

  }, [configuration, activeConfiguration, loadingCfg, user, activeTrueCaser, activePunctuator, activeInverseTextNormalizator])

  const handlePcOnModelChange = (languageParameter?:string, domainParameter?: string, automaticPunctuationDictionary?: ITttPipelines) => {
    let automaticPunctuator: IPunctuator = {
      language: null,
      domain: null,
      model: null,
      toggled: false,
    }

    let dictionary: ITttPipelines | null = null

    if (automaticPunctuationDictionary) dictionary = automaticPunctuationDictionary;
    else if (automaticPunctuation) dictionary = automaticPunctuation

    if (!dictionary) return automaticPunctuator;
    
    const selectedLanguage = languageParameter
    const selectedDomain = domainParameter
    if (!selectedLanguage) throw new Error("Language should be selected if model is changed")
    if (!selectedDomain) throw new Error("Domain should be selected if model is changed")

    const languages = Object.keys(dictionary)
    languages.forEach(language => {
      if (language !== selectedLanguage) return; // Language must be selected language else, truecaser will probably return false values.
      const domains = Object.keys(dictionary[language])
      domains.forEach(domain => {
        if (domain !== selectedDomain && domain !== "*") return; // if domain of truecaser is not selected domain or *, than it is not viable for usage with current configuration.
        const models = Object.keys(dictionary[language][domain])
        models.forEach(model => {
          if (automaticPunctuator.model !== null && automaticPunctuator.model.name !== "*") return;
          if (automaticPunctuator.language !== selectedLanguage) automaticPunctuator.language = language
          if (automaticPunctuator.domain !== selectedDomain) automaticPunctuator.domain = domain;
          if (automaticPunctuator.model === null || automaticPunctuator.model.name === "*") {
            const modelObj = dictionary[language][domain][model]
            console.log(modelObj)
            let enableSplitToSentences = { value: false, enabled: false }
            let enableTc = { value: false, enabled: false }
            modelObj.parameters.forEach(parameter => {
              if (parameter.name === "enableSplitToSentences") {
                let value = activePunctuator.model && activePunctuator.model.enableSplitToSentences ? activePunctuator.model.enableSplitToSentences.value : false
                enableSplitToSentences = { value: value, enabled: true }
              } else if (parameter.name === "enableTc") {
                let value = activePunctuator.model && activePunctuator.model.enableTc ? activePunctuator.model.enableTc.value : false
                enableTc = { value: value, enabled: true }
              }
            })

            automaticPunctuator.model = { name: model, serviceProvider: modelObj.serviceProvider, enableSplitToSentences, enableTc };
            automaticPunctuator.toggled = activePunctuator.toggled
          }
        })
      })
    })

    return automaticPunctuator;
  }
  const handleTcOnModelChange = (language?: string, domain?: string, automaticTruecasingDictionary?: ITttPipelines) => {
    let automaticTruecaser: ITrueCaser = {
      language: null,
      domain: null,
      model: null,
      toggled: false,
    }

    let dictionary: ITttPipelines | null = null

    if (automaticTruecasingDictionary) dictionary = automaticTruecasingDictionary;
    else if (automaticTruecaser) dictionary = automaticTruecasing

    if (!dictionary) return automaticTruecaser;

    const selectedLanguage = language
    const selectedDomain = domain
    if (!selectedLanguage) throw new Error("Language should be selected if model is changed")
    if (!selectedDomain) throw new Error("Domain should be selected if model is changed")

    const languages = Object.keys(dictionary)
    languages.forEach(language => {
      if (language !== selectedLanguage) return; // Language must be selected language else, truecaser will probably return false values.
      const domains = Object.keys(dictionary[language])
      domains.forEach(domain => {
        if (domain !== selectedDomain && domain !== "*") return; // if domain of truecaser is not selected domain or *, than it is not viable for usage with current configuration.
        const models = Object.keys(dictionary[language][domain])
        models.forEach(model => {
          if (automaticTruecaser.model !== null && automaticTruecaser.model.name !== "*") return;
          if (automaticTruecaser.language !== selectedLanguage) automaticTruecaser.language = language
          if (automaticTruecaser.domain !== selectedDomain) automaticTruecaser.domain = domain;
          if (automaticTruecaser.model === null || automaticTruecaser.model.name === "*") {
            const modelObj = dictionary[language][domain][model]
            automaticTruecaser.model = { name: model, serviceProvider: modelObj.serviceProvider };
            automaticTruecaser.toggled = activeTrueCaser.toggled
          }

        })
      })
    })

    return automaticTruecaser;
  }

  const handleItnOnModelChange = (language?: string, domain?: string, inverseTextNormalizationDictionary?: ITttPipelines) => {
    let inverseTextNormalizator: IInverseTextNormalizator = {
      language: null,
      domain: null,
      model: null,
      toggled: false,
    }

    let dictionary: ITttPipelines | null = null

    if (inverseTextNormalizationDictionary) dictionary = inverseTextNormalizationDictionary;
    else if (inverseTextNormalization) dictionary = inverseTextNormalization

    if (!dictionary) return inverseTextNormalizator;

    const selectedLanguage = language
    const selectedDomain = domain
    if (!selectedLanguage) throw new Error("Language should be selected if model is changed")
    if (!selectedDomain) throw new Error("Domain should be selected if model is changed")

    const languages = Object.keys(dictionary)
    languages.forEach(language => {
      if (language !== selectedLanguage) return; // Language must be selected language else, truecaser will probably return false values.
      const domains = Object.keys(dictionary[language])
      domains.forEach(domain => {
        if (domain !== selectedDomain && domain !== "*") return; // if domain of truecaser is not selected domain or *, than it is not viable for usage with current configuration.
        const models = Object.keys(dictionary[language][domain])
        models.forEach(model => {
          if (inverseTextNormalizator.model !== null && inverseTextNormalizator.model.name !== "*") return;
          if (inverseTextNormalizator.language !== selectedLanguage) inverseTextNormalizator.language = language
          if (inverseTextNormalizator.domain !== selectedDomain) inverseTextNormalizator.domain = domain;
          if (inverseTextNormalizator.model === null || inverseTextNormalizator.model.name === "*") {
            const modelObj = dictionary[language][domain][model]
            inverseTextNormalizator.model = { name: model, serviceProvider: modelObj.serviceProvider };
            inverseTextNormalizator.toggled = activeInverseTextNormalizator.toggled
          }

        })
      })
    })

    return inverseTextNormalizator;
  }

  const handleModelChange = useCallback(async (model: IPipelineModel) => {
    if (loadingCfg || model.name === activeConfiguration.model?.name) return;

    //f: Set framework as implicit, which means it should be ignored in language filtering
    if (!configuration) {
      enqueueSnackbar('Izbrane so neveljavne nastavitve.', { variant: 'error' });
      return;
    }
    if (!activeConfiguration.language) {
      enqueueSnackbar('Izberite jezik transkripcije.', { variant: 'error' });
      return;
    }
    if (!activeConfiguration.domain) {
      enqueueSnackbar('Izberite domeno.', { variant: 'error' });
      return;
    }

    const modelName = model.name

    let currentFramework: string | null = null
    if (activeConfiguration.serviceProvider) {
      currentFramework = possibleFrameworks.includes(activeConfiguration.serviceProvider) && isUserFrameworkChanger ? activeConfiguration.serviceProvider : null
    }
    if (currentFramework) {
      const selectedModel = configuration[currentFramework][activeConfiguration.language][activeConfiguration.domain][modelName]

      let pipelineModel = convertLocalModelToPipelineModel(selectedModel, modelName, currentFramework)

      const includesEnableSd = selectedModel.parameters.filter(parameter => parameter.name === "enableSd").length > 0
      const includesEnableInterims = selectedModel.parameters.filter(parameter => parameter.name === "enableInterims").length > 0
      if (includesEnableSd && activeConfiguration.model && activeConfiguration.model.enableSd) {
        pipelineModel.enableSd = { value: activeConfiguration.model.enableSd.value, enabled: true }
      }

      if (includesEnableInterims && activeConfiguration.model && activeConfiguration.model.enableInterims) {
        pipelineModel.enableInterims = { value: activeConfiguration.model.enableInterims.value, enabled: true }
      }

      try {
        const status = await getModelStatus(undefined, undefined, modelName)
        const newWordsStatus = await getNewWordsStatus(`${activeConfiguration.language}%3A${activeConfiguration.domain}%3A${modelName}`);
        pipelineModel = {
          ...pipelineModel,
          isUpdatable: status.isUpdatable && newWordsStatus,
          isUpdating: status.isUpdating
        }
      } catch (error) {
        pipelineModel = {
          ...pipelineModel,
          isUpdatable: false,
          isUpdating: false,
        }
      }

      const inverseTextNormalizationModel = handleItnOnModelChange(activeConfiguration.language, activeConfiguration.domain)
      const punctuationModel = handlePcOnModelChange(activeConfiguration.language, activeConfiguration.domain)
      const truecaserModel = handleTcOnModelChange(activeConfiguration.language, activeConfiguration.domain)

      dispatch(setActiveConfiguration({ ...activeConfiguration, model: pipelineModel }))
      dispatch(setActiveInverseTextNormalizator(inverseTextNormalizationModel))
      dispatch(setActivePunctuator(punctuationModel))
      dispatch(setActiveTruecaser(truecaserModel))
    } else {

      const frameworks = Object.keys(configuration)

      for (let frameworkIndex = 0; frameworkIndex < frameworks.length; frameworkIndex++) {
        const frameworkKey = frameworks[frameworkIndex]
        const framework = configuration[frameworkKey]
        const languages = Object.keys(framework).filter(language => language === activeConfiguration.language)
        for (let languageIndex = 0; languageIndex < languages.length; languageIndex++) {
          const languageKey = languages[languageIndex]
          const language = framework[languageKey]
          const domains = Object.keys(language).filter(domain => domain === activeConfiguration.domain)
          for (let domainIndex = 0; domainIndex < domains.length; domainIndex++) {
            const domainKey = domains[domainIndex]
            const domain = language[domainKey]
            const model = domain[modelName]
            if (model) {

              let pipelineModel = convertLocalModelToPipelineModel(model, modelName, frameworkKey)

              const includesEnableSd = model.parameters.filter(parameter => parameter.name === "enableSd").length > 0
              const includesEnableInterims = model.parameters.filter(parameter => parameter.name === "enableInterims").length > 0
              if (includesEnableSd && activeConfiguration.model && activeConfiguration.model.enableSd) {
                pipelineModel.enableSd = { value: activeConfiguration.model.enableSd.value, enabled: true }
              }

              if (includesEnableInterims && activeConfiguration.model && activeConfiguration.model.enableInterims) {
                pipelineModel.enableInterims = { value: activeConfiguration.model.enableInterims.value, enabled: true }
              }

              try {
                //TODO: Figure this one out.
                const status = await getModelStatus(languageKey, domainKey, modelName)
                const newWordsStatus = await getNewWordsStatus(`${languageKey}%3A${domainKey}%3A${modelName}`);
                pipelineModel = {
                  ...pipelineModel,
                  isUpdatable: status.isUpdatable && newWordsStatus,
                  isUpdating: status.isUpdating
                }
              } catch (error) {
                pipelineModel = {
                  ...pipelineModel,
                  isUpdatable: false,
                  isUpdating: false,
                }
              }

              const newActiveConf = {
                serviceProvider: frameworkKey,
                language: languageKey,
                domain: domainKey,
                model: pipelineModel,
              }

              setPossibleLanguages(getLanguages(isUserFrameworkChanger, configuration, frameworkKey))
              setPossibleDomains(getDomains(isUserFrameworkChanger, configuration, frameworkKey, languageKey))
              setPossibleVersions(getVersions(isUserFrameworkChanger, configuration, frameworkKey, languageKey, domainKey))

              const inverseTextNormalizationModel = handleItnOnModelChange(languageKey, domainKey)
              const punctuationModel = handlePcOnModelChange(languageKey, domainKey)
              const truecaserModel = handleTcOnModelChange(languageKey, domainKey)

              dispatch(setActiveConfiguration(newActiveConf))
              dispatch(setActiveInverseTextNormalizator(inverseTextNormalizationModel))
              dispatch(setActivePunctuator(punctuationModel))
              dispatch(setActiveTruecaser(truecaserModel))
              return;
            }
          }
        }
      }

      enqueueSnackbar('Izbrane so neveljavne nastavitve', { variant: 'error' });
    }
  }, [configuration, activeConfiguration, loadingCfg, user, activeTrueCaser, activePunctuator, activeInverseTextNormalizator]);

  const handleDenormalizationToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (loadingCfg) return;
    const updateObj = {
      ...activeInverseTextNormalizator,
      toggled: event.target.checked
    }
    dispatch(setActiveInverseTextNormalizator(updateObj))
  };

  const handleTruecasingToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (loadingCfg) return;
    const updateObj = {
      ...activeTrueCaser,
      toggled: event.target.checked
    }
    dispatch(setActiveTruecaser(updateObj))
  };

  const handlePunctuationToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (loadingCfg) return;
    const updateObj = {
      ...activePunctuator,
      toggled: event.target.checked
    }

    dispatch(setActivePunctuator(updateObj))
  };

  const handleDiarizationToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (loadingCfg) return;
    if (activeConfiguration.model?.enableSd) {
      const modelCopy: IPipelineModel = { ...activeConfiguration.model, enableSd: { ...activeConfiguration.model.enableSd, value: event.target.checked } }
      dispatch(setActiveConfiguration({ ...activeConfiguration, model: modelCopy }))
    }
  };

  const handleDictatedCommandsToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (loadingCfg) return;
    if (activeConfiguration.model?.dictatedCommands) {
      const modelCopy: IPipelineModel = { ...activeConfiguration.model, dictatedCommands: { ...activeConfiguration.model.dictatedCommands, value: event.target.checked } }
      dispatch(setActiveConfiguration({ ...activeConfiguration, model: modelCopy }))
    }
  };

  const handleDictatedPonctuationsToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (loadingCfg) return;
    if (activeConfiguration.model?.dictatedPunctuations) {
      const modelCopy: IPipelineModel = { ...activeConfiguration.model, dictatedPunctuations: { ...activeConfiguration.model.dictatedPunctuations, value: event.target.checked } }
      dispatch(setActiveConfiguration({ ...activeConfiguration, model: modelCopy }))
    }
  };

  const handleInterimsToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (loadingCfg) return;
    if (activeConfiguration.model?.enableInterims) {
      const modelCopy: IPipelineModel = { ...activeConfiguration.model, enableInterims: { ...activeConfiguration.model.enableInterims, value: event.target.checked } }
      dispatch(setActiveConfiguration({ ...activeConfiguration, model: modelCopy }))
    }
  };

  const handleRealFinalsToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (loadingCfg) return;
    if (activePunctuator.model?.enableSplitToSentences) {
      const modelCopy: IAutomaticPunctuationModel = { ...activePunctuator.model, enableSplitToSentences: { ...activePunctuator.model.enableSplitToSentences, value: event.target.checked } }
      dispatch(setActivePunctuator({ ...activePunctuator, model: modelCopy }))
    }
  };

  const handlePunctuationTruecaserToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (loadingCfg) return;
    if (activePunctuator.model?.enableTc) {
      const modelCopy: IAutomaticPunctuationModel = { ...activePunctuator.model, enableTc: { ...activePunctuator.model.enableTc, value: event.target.checked } }
      dispatch(setActivePunctuator({ ...activePunctuator, model: modelCopy }))
    }
  };


  const [testingSwitches, setTestingSwitches] = useState<{ fw: boolean, l: boolean, d: boolean, v: boolean }>({
    fw: false,
    l: false,
    d: false,
    v: false,
  })

  const onChange = (checked: boolean, key: string) => {
    if (key === "fw") {
      setTestingSwitches({
        fw: checked,
        l: false,
        d: false,
        v: false,
      })
    } else if (key === "l") {
      setTestingSwitches({
        fw: false,
        l: checked,
        d: false,
        v: false,
      })
    } else if (key === "d") {
      setTestingSwitches({
        fw: false,
        l: false,
        d: checked,
        v: false,
      })
    }
    else if (key === "v") {
      setTestingSwitches({
        fw: false,
        l: false,
        d: false,
        v: checked,
      })
    }
  }

  const [openFramework, setOpenFramework] = useState<boolean>(false);
  const [openLanguage, setOpenLanguage] = useState<boolean>(false);
  const [openDomain, setOpenDomain] = useState<boolean>(false);
  const [openModel, setOpenModel] = useState<boolean>(false);

  const handleFrameworkToggle = (value: boolean) => {
    if (value) { //close
      setOpenFramework(true);
      setOpenLanguage(false);
      setOpenDomain(false);
      setOpenModel(false);
      return;
    }

    //open
    setOpenFramework(false)
  }

  const handleLanguageToggle = (value: boolean) => {
    if (value) { //close
      setOpenLanguage(true);
      setOpenFramework(false);
      setOpenDomain(false);
      setOpenModel(false);
      return;
    }

    //open
    setOpenLanguage(false)
  }

  const handleDomainToggle = (value: boolean) => {
    if (value) { //close
      setOpenDomain(true);
      setOpenFramework(false);
      setOpenLanguage(false);
      setOpenModel(false);
      return;
    }

    //open
    setOpenDomain(false)
  }

  const handleModelToggle = (value: boolean) => {
    if (value) {
      setOpenModel(true);
      setOpenFramework(false);
      setOpenLanguage(false);
      setOpenDomain(false);
      return;
    }

    setOpenModel(false)
  }

  return (
    <>
      <AnimatePresence>
        {show && (
          <>
            <div className="modal_wrapper" onClick={handleCloseSettings} />
            <motion.form
              ref={settingsRef}
              className="settings_form"
              onSubmit={onSubmit}
              autoComplete="off"
              variants={settingsVariants}
              initial="hidden"
              animate="visible"
              exit="hidden"
            >
              <button
                type="button"
                style={{
                  // color: '#707070',
                  display: 'inline-flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  position: 'absolute',
                  top: 25,
                  right: 25,
                }}
                onClick={handleCloseSettings}
              >
                <ClearIcon />
              </button>
              <div className="settings_container">
                <div className="tittle_style">Nastavitve</div>
                <div className="credentials_wrapper">
                  <div className="credentials_title_line">
                    <div>Avtentikacija</div>
                  </div>
                  <div className="credentials_userpwline">
                    <>
                      <TextField
                        className={user ? "username_input_logged_in" : "username_input_logged_out"}
                        disabled={isAuthenticated}
                        value={user ? user.username : ''}
                        error={typeof authFailed === 'string'}
                        name="username"
                        placeholder="Uporabniško ime"
                        variant='standard'
                        // autoComplete="current-username"
                        fullWidth
                        onChange={(e) => {
                          setAuthFailed(false);
                        }}
                        helperText={authFailed || null}
                      />
                      <button
                        type="button"
                        onClick={handleLogoutClick}
                        className="cancel_button"
                        style={{
                          display: 'inline-flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginRight: 0,
                        }}
                      >
                        ODJAVA
                      </button>
                    </>
                  </div>
                </div>
                {user && !loadingCfg && configError && (
                  <p>Prišlo je do napake pri generiranju nastavitev. Poskusite osvežiti stran.</p>
                )}
                {user && loadingCfg && <LinearProgress style={{ width: '100%', marginTop: '10px' }} color={'secondary'} />}
                {quota && user && (
                  <div className='usage_container'>
                    <p className="usage_title">Poraba(v sekundah):</p>
                    <p className='usage_data'>Danes: {quota.daily.secondsConsumed}</p>
                    <p className='usage_data'>Ta teden: {quota.weekly.secondsConsumed}</p>
                    <p className='usage_data'>Ta mesec: {quota.monthly.secondsConsumed}</p>
                    <p className='usage_data'>To leto: {quota.yearly.secondsConsumed}</p>
                    <p className='usage_data'>Skupaj: {quota.total.secondsConsumed}</p>
                  </div>
                )}

                {!configError && user && activeConfiguration.language && (
                  <>
                    <div className="transcription_wrapper">
                      <div className="transcription_title_line">
                        <div>Transkripcija</div>
                      </div>

                      <div className="options_wrapper">
                        {activeQuota && activeQuota.chunk.secondsLimit && (
                          <div className="checkbox_wrapper">
                            <div>Omejitev uporabe</div>
                            <div className='consumption_container'>
                              <Tooltip placement='top' title={`Dosegli ste ${(activeQuota.chunk.secondsConsumed / activeQuota.chunk.secondsLimit * 100).toFixed(2)}% vaše ${translateChunkKey(activeQuota.key)} zakupljene količine, oz. ${transformConsumedAndLimitToReadable(activeQuota.chunk.secondsConsumed, activeQuota.chunk.secondsLimit)}.`}>
                                <div className='consumption_wrapper'>
                                  <ConsumptionBar value={activeQuota.chunk.secondsConsumed} maxValue={activeQuota.chunk.secondsLimit} />
                                </div>
                              </Tooltip>
                              {activeQuota.chunk.secondsLimit && (
                                <button className='consumption_button_wrapper' onClick={showConsumptionModal}>
                                  <img src="info.svg" alt="Info svg" className='consumption_button_image' />
                                </button>
                              )}
                            </div>
                          </div>
                        ) || activeQuota && (
                          <div className="checkbox_wrapper">
                            <div>Trenutna poraba</div>
                            <div className='consumption_container'>
                              <Tooltip placement='top' title="Poraba v obračunskem obdobju">
                                <p data-consumptionInSeconds={activeQuota.chunk.secondsConsumed} data-testid='consumption_paragraph' className='consumption_without_limit_paragraph'>{transformConsumedToReadable(activeQuota.key, activeQuota.chunk.secondsConsumed)}</p>
                              </Tooltip>
                              <button className='consumption_button_wrapper' onClick={showConsumptionModal}>
                                <img src="info.svg" alt="Info svg" className='consumption_button_image' />
                              </button>
                            </div>
                          </div>
                        )}

                        {isUserFrameworkChanger && (
                          <OptionSelector
                            opened={openFramework}
                            title="Ogrodje"
                            value={activeConfiguration.serviceProvider}
                            options={possibleFrameworks}
                            updateValue={handleFrameworkChange}
                            handleOpened={handleFrameworkToggle}
                            extractValues={value => value} />
                        )}
                        <OptionSelector
                          opened={openLanguage}
                          title="Jezik"
                          value={activeConfiguration.language}
                          options={possibleLanguages}
                          updateValue={handleLanguageChange}
                          handleOpened={handleLanguageToggle}
                          extractValues={value => value} />
                        <OptionSelector
                          opened={openDomain}
                          disabled={activeConfiguration.language === null}
                          title="Domena"
                          value={activeConfiguration.domain}
                          options={possibleDomains}
                          updateValue={handleDomainChange}
                          handleOpened={handleDomainToggle}
                          extractValues={value => value} />
                        <OptionSelector
                          opened={openModel}
                          disabled={activeConfiguration.domain === null}
                          title="Model"
                          value={activeConfiguration.model}
                          options={possibleVersions}
                          updateValue={handleModelChange}
                          handleOpened={handleModelToggle}
                          extractValues={value => value.name} />

                        {activeInverseTextNormalizator.language !== null && (
                          <div
                            className="checkbox_wrapper"
                            style={{
                              opacity: loadingCfg ? 0.4 : 1,
                              pointerEvents: loadingCfg ? 'none' : 'all',
                            }}
                          >
                            <div>Denormalizacija</div>
                            <Checkbox
                              disabled={loadingCfg}
                              checked={activeInverseTextNormalizator.toggled}
                              onChange={handleDenormalizationToggle}
                              color="primary"
                            />
                          </div>
                        )}

                        {activePunctuator.language !== null && (
                          <div
                            className="checkbox_wrapper"
                            style={{
                              opacity: loadingCfg ? 0.4 : 1,
                              pointerEvents: loadingCfg ? 'none' : 'all',
                            }}
                          >
                            <div>Samodejno postavljanje ločil</div>
                            <Checkbox
                              disabled={loadingCfg}
                              checked={activePunctuator.toggled}
                              onChange={handlePunctuationToggle}
                              color="primary"
                            />
                          </div>
                        )}

                        {activePunctuator.toggled && activePunctuator.model?.enableTc?.enabled && (
                          <div
                            className="checkbox_wrapper"
                            style={{
                              opacity: loadingCfg ? 0.4 : 1,
                              pointerEvents: loadingCfg ? 'none' : 'all',
                            }}
                          >
                            <div>Samodejno postavljanje velikih začetnic</div>
                            <Checkbox
                              data-testid="enablePunctuatorTruecasing-checkbox"
                              data-value={activePunctuator.model?.enableTc?.value ? true : false}
                              disabled={loadingCfg}
                              checked={activePunctuator.model?.enableTc?.value ? true : false}
                              onChange={handlePunctuationTruecaserToggle}
                              color="primary"
                            />
                          </div>
                        )}

                        {/*activeConfiguration.model?.enableSd && (
                          <div
                          className="checkbox_wrapper"
                          style={{
                            opacity: loadingCfg ? 0.4 : 1,
                            pointerEvents: loadingCfg ? 'none' : 'all',
                          }}
                        >
                          <div>Samodejno ločevanje govorcev</div>
                          <Checkbox
                            data-testid="diarization-checkbox"
                            data-value={activeConfiguration.model?.enableSd?.value ? true : false}
                            disabled={loadingCfg}
                            checked={activeConfiguration.model?.enableSd?.value ? true : false}
                            onChange={handleDiarizationToggle}
                            color="primary"
                          />
                        </div>
                        )*/}

                        {activeConfiguration.model?.dictatedPunctuations && (
                          <div
                            className="checkbox_wrapper"
                            style={{
                              opacity: loadingCfg ? 0.4 : 1,
                              pointerEvents: loadingCfg ? 'none' : 'all',
                            }}
                          >
                            <div>Narekovana ločila</div>
                            <Checkbox
                              disabled={loadingCfg}
                              checked={activeConfiguration.model?.dictatedPunctuations?.value ? true : false}
                              onChange={handleDictatedPonctuationsToggle}
                              color="primary"
                            />
                          </div>
                        )}

                        {activeConfiguration.model?.dictatedCommands && (
                          <div
                            className="checkbox_wrapper"
                            style={{
                              opacity: loadingCfg ? 0.4 : 1,
                              pointerEvents: loadingCfg ? 'none' : 'all',
                            }}
                          >
                            <div>Narekovani ukazi</div>
                            <Checkbox
                              disabled={loadingCfg}
                              checked={activeConfiguration.model?.dictatedCommands?.value ? true : false}
                              onChange={handleDictatedCommandsToggle}
                              color="primary"
                            />
                          </div>
                        )}

                        <div
                          className="checkbox_wrapper"
                          style={{
                            opacity: activeConfiguration.model && activeConfiguration.model.isUpdatable && !activeConfiguration.model.isUpdating ? 1 : 0.4,
                            pointerEvents: activeConfiguration.model && activeConfiguration.model.isUpdatable && !activeConfiguration.model.isUpdating ? 'all' : 'none',
                          }}
                        >
                          <div>Slovar</div>
                          <UpdatingDictSection
                            isModelUpdatable={activeConfiguration.model?.isUpdatable}
                            isModelUpdating={activeConfiguration.model?.isUpdating}
                            version={activeConfiguration.model ? activeConfiguration.model.name : null}
                            domain={activeConfiguration.domain}
                            language={activeConfiguration.language}
                          />
                        </div>

                        {activeConfiguration.model?.enableInterims && (
                          <div
                            className="checkbox_wrapper"
                            style={{
                              opacity: loadingCfg ? 0.4 : 1,
                              pointerEvents: loadingCfg ? 'none' : 'all',
                            }}
                          >
                            <div style={{ fontStyle: 'italic' }}>Prikazuj delne transkripte</div>
                            <Checkbox
                              disabled={loadingCfg}
                              checked={activeConfiguration.model?.enableInterims?.value ? true : false}
                              onChange={handleInterimsToggle}
                              color="primary"
                            />
                          </div>
                        )}

                        {activePunctuator.model?.enableSplitToSentences?.enabled && (
                          <div
                            className="checkbox_wrapper"
                            style={{
                              opacity: loadingCfg ? 0.4 : 1,
                              pointerEvents: loadingCfg ? 'none' : 'all',
                            }}
                          >
                            <div style={{ fontStyle: 'italic' }}>Razbijaj končne transkripte na povedi</div>
                            <Checkbox
                              data-testid="enableSplitToSentences-checkbox"
                              data-value={activePunctuator.model?.enableSplitToSentences?.value ? true : false}
                              disabled={loadingCfg}
                              checked={activePunctuator.model?.enableSplitToSentences?.value ? true : false}
                              onChange={handleRealFinalsToggle}
                              color="primary"
                            />
                          </div>
                        )}

                        {isAdmin && (
                          <div className="checkbox_wrapper">
                            <div style={{ fontStyle: 'italic' }}>Prikaži lastnosti modela</div>
                            <button onClick={openModelInfoModal}>
                              <img src="info.svg" alt="Info svg" style={{ width: 24, height: 24 }} />
                            </button>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="transcription_wrapper">
                      <div className="transcription_title_line">
                        <div>Zgodovina</div>
                      </div>
                      <div className="options_wrapper">
                        <NumberOfRowsPerPageSetter />
                        <div
                          className="checkbox_wrapper"
                        // style={{
                        //   opacity: !currentModelAvailable || !allowModelUpdating ? 0.4 : 1,
                        //   pointerEvents: !currentModelAvailable || !allowModelUpdating ? 'none' : 'all',
                        // }}
                        >
                          <div>Privzete labele</div>
                          <AddLabelsButton />
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </motion.form>
          </>
        )}
      </AnimatePresence>
      {config && modelInfoModalVisible && (
        <div
          onClick={closeModelInfoModal}
          style={{
            zIndex: 35,
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#0000001A',
          }}
        >
          <div
            onClick={preventOnClick}
            style={{
              paddingLeft: 39,
              paddingTop: 33,
              paddingBottom: 37,
              paddingRight: 44,
              width: '40%',
              backgroundColor: '#F2F2F2',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {(activeConfiguration.serviceProvider && activeConfiguration.language && activeConfiguration.domain && activeConfiguration.model && (
              <>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: 59,
                  }}
                >
                  <p style={{ margin: 0, fontSize: 36, fontWeight: 300, fontFamily: 'Roboto' }}>
                    {`${activeConfiguration.serviceProvider}:${activeConfiguration.language}:${activeConfiguration.domain}:${activeConfiguration.model.name}`}
                  </p>
                  <button type="button" onClick={closeModelInfoModal}>
                    <ClearIcon />
                  </button>
                </div>
                <p
                  style={{
                    fontSize: 12,
                    fontWeight: 700,
                    fontFamily: 'Roboto',
                    marginBottom: 22,
                    marginTop: 0,
                  }}
                >
                  Lastnosti modela
                </p>
                <pre className="settings_show_scrollbar">{JSON.stringify(modelMetadata, null, 2)}</pre>
              </>
            )) || (
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                  <p>Podatki za model trenutno niso na voljo</p>
                  <button type="button" onClick={closeModelInfoModal}>
                    <ClearIcon />
                  </button>
                </div>
              )}
          </div>
        </div>
      )}
    </>
  );
};

interface RegenStatusData {
  startedAt?: string;
  statusCode: DictStatesEnum;
  enableModelUpdating?: boolean;
}

const UpdatingDictSection = ({
  domain,
  version,
  language,
  isModelUpdating,
  isModelUpdatable,
}: DictProps) => {
  const [state, setState] = useState<DictStatesEnum>(DictStatesEnum.NOT_RUNNING);
  const navigate = useNavigate();

  const handleRegenerateClick = async () => {
    if (!language || !domain || !version) {
      return;
    }
    try {
      const data = await putTagOperationStatus(`${language}%3A${domain}%3A${version}`, TagOperation.UPDATE);
      /*if (data.status === 204) {
        setState(DictStatesEnum.RUNNING);
        //trigger refetch?
      }
      // console.log('Regenerate data:', data);*/
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="dict_commands_wrapper">
      {state === DictStatesEnum.FAILED && <FailedSyncingIcon />}
      {isModelUpdatable && !isModelUpdating && (
        <>
          {/*<span className="num_of_new_words">{numOfNewWords}</span>*/}
          <button
            className="update_dict_button sync"
            type="button"
            onClick={handleRegenerateClick}
          >
            <DictDesynchedIcon />
          </button>
        </>
      )}
      {!isModelUpdatable && !isModelUpdating && <DictSynchedIcon />}
      {isModelUpdating && <AnimatedInProgress style={{ margin: '0 4px' }} />}
      {config.environment !== 'demo' && (
        <button
          onClick={() => navigate('/dictionary')}
          className="update_dict_button"
          disabled={false}
          type="button"
          style={{ cursor: 'pointer', opacity: 1, pointerEvents: 'all' }}>
          <DictEditIcon />
        </button>
      )}
    </div>
  );
};

const AnimatedInProgress = (props: any) => {
  const [iconNum, setIconNum] = useState<number>(0);

  useInterval(
    () => {
      setIconNum((prev) => {
        if (prev === 3) {
          return 0;
        }

        return prev + 1;
      });
    },
    600,
    []
  );

  return (
    <div style={props.style || {}} className="dict_aimation_wrapper">
      {iconNum === 0 && <DictSyncingIcon0 />}
      {iconNum === 1 && <DictSyncingIcon1 />}
      {iconNum === 2 && <DictSyncingIcon2 />}
      {iconNum === 3 && <DictSyncingIcon3 />}
    </div>
  );
};

const rows = {
  min: 5,
  max: 35,
};

const NumberOfRowsPerPageSetter = () => {
  const pageSize = useAppSelector(state => state.app.dashboardPagination.pageSize);
  const userName = useAppSelector(state => state.app.user?.username);
  const dispatch = useAppDispatch();

  const handleChange = (e: any) => {
    const { value } = e.target;
    const num = parseInt(value, 10);
    if (num > rows.max || num < rows.min) return;
    dispatch(setDashboardPagination({ pageSize: num }));
    userName &&
      localStorage.setItem(
        `editor-${userName}-page-size`,
        JSON.stringify({
          pageSize: num,
        })
      );
  };

  const handleBlur = () => {
    if (!pageSize || pageSize === 0 || isNaN(pageSize) || pageSize > rows.max || pageSize < rows.min) {
      dispatch(setDashboardPagination({ pageSize: rows.min }));
      userName &&
        localStorage.setItem(
          `editor-${userName}-page-size`,
          JSON.stringify({
            pageSize: rows.min,
          })
        );
    }
  };

  return (
    <TextField
      style={{
        fontSize: '14px',
      }}
      className="type_number"
      type="number"
      value={pageSize}
      onChange={handleChange}
      name="historyNumberOfPages"
      variant='standard'
      InputProps={{
        inputProps: { min: rows.min, max: rows.max },
        onBlur: handleBlur,
        startAdornment: (
          <InputAdornment position="start" className="input_adornment">
            Število zapisov na stran
          </InputAdornment>
        ),
      }}
    />
  );
};

export default SettingsDrawer;